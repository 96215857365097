<template>
  <div class="main-content">
    <div class="layout-px-spacing">
      <div class="row layout-top-spacing">
        <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
          <div class="text-right">
            <button
              type="button"
              class="mb-2 mr-2 btn btn-primary"
              data-toggle="modal"
              data-target="#searchModal"
            >
              <i class="fa fa-search"></i>
            </button>
            <router-link to="/exemplary-services/all" class="mb-2 mr-2 btn btn-info btn-md"><i class="fa fa-eye"></i> Exemplary Services</router-link>
            <b-button
              variant="dark"
              @click="fetchUpcomingVisits()"
              class="mb-2"
              v-b-toggle.sidebar-right
            >
              <i class="fa fa-calendar"></i> Not Yet Booked
            </b-button>

            <b-sidebar
              id="sidebar-right"
              title=""
              width="100vw"
              right
              shadow
              backdrop-variant="dark"
              backdrop
            >
              <template #footer="{ hide }">
                <div
                  class="px-3 py-2 d-flex bg-dark text-light align-items-center"
                >
                  <strong class="mr-auto"
                    >Appointments that havent yet been booked for the current
                    month</strong
                  >
                  <b-button size="sm" @click="hide">Close</b-button>
                </div>
              </template>
              <div class="px-3 py-2">
                <div v-if="loadingUpcoming">
                  <b-skeleton-table
                    :rows="10"
                    :columns="5"
                    :table-props="{ bordered: true, striped: true }"
                  ></b-skeleton-table>
                </div>

                <div v-else class="mt-4 mb-4 text-center table-responsive">
                  <div class="text-center">
                    <div class="mx-auto form-group w-50">
                      <label for="range">Choose Time Range</label>
                      <select
                        class="form-control"
                        v-model="range"
                        id="range"
                        @change="sortByTimeRange()"
                      >
                        <option value="thisweek">This Week</option>
                        <option value="nextweek">Next Week</option>
                        <option value="thismonth">This Month</option>
                        <option value="nextmonth">Next Month</option>
                        <option value="all">All</option>
                      </select>
                    </div>
                  </div>

                  <table
                    id="upcomingVisits"
                    class="table table-hover non-hover"
                    style="width: 100%"
                  >
                    <thead>
                      <tr>
                        <th>Doctor</th>
                        <th>Sponsor</th>
                        <th>Elderly</th>
                        <th>Last Appointment Date</th>
                        <th>Initial Next Appointment</th>
                        <th>Expected Next Appointment</th>
                        <th>Next Appointment Status</th>
                        <th>Days since expected appointment</th>
                        <th>Visits Left</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in filteredUpcomings" :key="item.id">
                        <td>
                          <span v-if="item.doctor != null"
                            >Dr. {{ item.doctor.firstname }}
                            {{ item.doctor.lastname }}</span
                          >
                          <span v-else>None</span>
                        </td>
                        <td>{{ item.user.name }}</td>
                        <td>{{ item.firstname }} {{ item.lastname }}</td>
                        <td>
                          {{
                            formatDate(item.lastappointment.appointment_date)
                          }}
                        </td>
                        <td class="text-danger">
                          {{ formatDate(item.initial_next_appointment_date) }}
                        </td>
                        <td>{{ formatDate(item.next_appointment_date) }}</td>
                        <td>
                          <button
                            v-if="item.app_booked"
                            class="badge badge-success"
                          >
                            Appointment Booked
                          </button>
                          <button v-else class="badge badge-dark">
                            Not Booked Yet
                          </button>
                        </td>
                        <td>
                          <span v-if="item.initial_next_app_delay != null"
                            >{{
                              parseDelayDuration(item.initial_next_app_delay)
                            }}
                          </span>
                        </td>
                        <td>{{ item.visit_count }}</td>
                        <td>
                          <button
                            @click="startFeedback(item)"
                            class="btn btn-xs"
                            :class="{
                              'btn-success': item.upcomingfeedback != null,
                              'btn-dark': item.upcomingfeedback == null,
                            }"
                            type="button"
                            data-toggle="modal"
                            data-target="#feedbackModal"
                          >
                            <i class="fa fa-comment"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </b-sidebar>
          </div>

          <div class="widget-content widget-content-area br-6">
            <div v-if="loading">
              <b-skeleton-table
                :rows="10"
                :columns="5"
                :table-props="{ bordered: true, striped: true }"
              ></b-skeleton-table>
            </div>

            <div v-else class="mt-4 mb-4 table-responsive">
              <table
                id="monthtlyvisitreports"
                class="table table-hover non-hover"
                style="width: 100%"
              >
                <thead>
                  <tr>
                    <th>Doctor</th>
                    <th>Appointment Date</th>
                    <th>Next Date</th>
                    <th>Elderly</th>
                    <th>Elderly Phone</th>
                    <th>Sponsor</th>
                    <th>Sponsor Phone</th>
                    <th>Visit Status</th>
                    <th>Report Status</th>
                    <th>Visit Cycle</th>
                    <th>Elderly Remarks</th>
                    <th>Sponsor Remarks</th>
                    <th>Report</th>
                    <th>Med Services</th>
                    <th>Management Remark</th>
                    <th>Created On</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="report in reports" :key="report.id">
                    <td>{{ report.doctor_name }}</td>
                    <td>{{ formatDate(report.appointment_date) }}</td>
                    <td>
                      <span v-if="report.next_visit == null">
                        {{ report.next_visit }}
                      </span>
                      <span v-else>
                        {{ formatDate(report.next_visit) }}
                      </span>
                    </td>
                    <td>
                      {{ report.elderly_name }}
                    </td>
                    <td>{{ report.elderly_phone }}</td>
                    <td>{{ report.sponsor_name }}</td>
                    <td>{{ report.sponsor_phone }}</td>
                    <td>
                      <span
                        class="p-2 badge badge-dark"
                        v-if="report.status == 1 && report.check == 0"
                      >
                        Not Checked In
                      </span>
                      <span
                        class="p-2 badge badge-dark"
                        v-else-if="report.status == 1 && report.check == 1"
                      >
                        Checked In
                      </span>
                      <span
                        class="p-2 badge badge-dark"
                        v-else-if="
                          report.status == 1 &&
                          report.check == 2 &&
                          report.summary == null
                        "
                      >
                        Checked Out
                      </span>
                      <span
                        class="p-2 badge badge-success"
                        v-else-if="
                          (report.status == 1 || report.status == 0) &&
                          (report.check == 2 ||
                            report.check == 3 ||
                            report.check == 4) &&
                          report.summary != null
                        "
                      >
                        Completed
                      </span>
                      <span
                        class="p-2 badge badge-primary"
                        v-else-if="report.status == 2"
                      >
                        Doctor Rescheduled
                      </span>
                      <span
                        class="p-2 badge badge-primary"
                        v-else-if="report.status == 3"
                      >
                        Patient Rescheduled
                      </span>
                      <span
                        class="p-2 badge badge-success"
                        v-else-if="report.status == 0"
                      >
                        Completed
                      </span>
                      <span class="p-2 badge badge-warning" v-else>
                        Awaiting Check In
                      </span>
                    </td>
                    <td>
                      <span
                        class="p-2 badge badge-success"
                        v-if="report.check == 3 || report.check == 4"
                      >
                        Report Submitted
                      </span>
                      <span class="p-2 badge badge-danger" v-else>
                        Report Not Ready
                      </span>
                    </td>
                    <td>
                      <span
                        v-if="report.visit_cycle == 1"
                        class="p-2 badge badge-success"
                      >
                        Completed
                      </span>
                      <span v-else class="p-2 badge badge-danger"
                        >Incomplete</span
                      >
                    </td>

                    <td>{{ report.elderly_remark }}</td>

                    <td>{{ report.sponsor_remark }}</td>
                    <td>{{ report.report }}</td>
                    <td>{{ report.med_services_remark }}</td>

                    <td>{{ report.management_remark }}</td>

                    <td>{{ formatDate(report.created_at) }}</td>
                    <td>
                      <div class="btn-group">
                        <button
                          type="button"
                          class="btn btn-success btn-sm"
                          @click="viewReport(report)"
                        >
                          <i class="fa fa-eye"></i>
                        </button>
                        <button
                          type="button"
                          class="btn btn-warning btn-sm"
                          @click="editReport(report)"
                        >
                          <i class="fa fa-edit"></i>
                        </button>
                        <button
                          class="btn btn-primary btn-sm"
                          type="button"
                          title="Add doctor review"
                          @click="showDoctorExemplary(report)"
                        >
                          <i class="fas fa-user-md"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="viewReportModal"
        role="dialog"
        aria-labelledby="viewReportModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl" role="document">
          <div class="modal-content" id="viewReportModalContent">
            <div class="modal-header">
              <div class="mr-auto">
                <small class="modal-title" id="viewReportModalLabel"
                  >Visit Report for Elderly {{ reportToView.elderly_name }} on
                  {{ formatFancyDate(reportToView.appointment_date) }}</small
                >
              </div>
              <div class="ml-auto">
                <span
                  class="p-2 badge badge-dark"
                  v-if="reportToView.status == 1 && reportToView.check == 0"
                >
                  Not Checked In
                </span>
                <span
                  class="p-2 badge badge-dark"
                  v-if="reportToView.status == 1 && reportToView.check == 1"
                >
                  Checked In
                </span>
                <span
                  class="p-2 badge badge-dark"
                  v-if="
                    reportToView.status == 1 &&
                    reportToView.check == 2 &&
                    reportToView.summary == null
                  "
                >
                  Checked Out
                </span>
                <span
                  class="p-2 badge badge-success"
                  v-if="
                    (reportToView.status == 1 || reportToView.status == 0) &&
                    (reportToView.check == 2 ||
                      reportToView.check == 3 ||
                      reportToView.check == 4) &&
                    reportToView.summary != null
                  "
                >
                  Completed
                </span>
                <span
                  class="p-2 badge badge-primary"
                  v-if="reportToView.status == 2"
                >
                  Doctor Rescheduled
                </span>
                <span
                  class="p-2 badge badge-primary"
                  v-if="reportToView.status == 3"
                >
                  Patient Rescheduled
                </span>
              </div>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-12 col-lg-3 col-12 d-flex d-lg-block">
                  <div class="card component-card_1">
                    <div class="text-center card-body">
                      <p class="small">
                        <span class="mg-b-0 small"> Doctor Name:</span> <br />
                        <strong>{{ reportToView.doctor_name }}</strong>
                      </p>
                      <p class="small">
                        <span class="mg-b-0 small"> Email:</span> <br />
                        <strong>{{ reportToView.doctor_email }}</strong>
                      </p>
                      <p class="small">
                        <span class="mg-b-0 small"> Phone:</span> <br />
                        <strong>{{ reportToView.doctor_phone }}</strong>
                      </p>
                    </div>
                  </div>

                  <div class="card component-card_1">
                    <div class="text-center card-body">
                      <p class="small">
                        <span class="mg-b-0 small"> Sponsor Name:</span> <br />
                        <strong>{{ reportToView.sponsor_name }}</strong>
                      </p>
                      <p class="small">
                        <span class="mg-b-0 small"> Email:</span> <br />
                        <strong>{{ reportToView.sponsor_email }}</strong>
                      </p>
                      <p class="small">
                        <span class="mg-b-0 small"> Phone:</span> <br />
                        <strong>{{ reportToView.sponsor_phone }}</strong>
                      </p>
                      <p class="small">
                        <span class="mg-b-0 small"> Alternative Phone:</span> <br />
                        <strong>{{ reportToView.alternative_contact }}</strong>
                      </p>
                    </div>
                  </div>

                  <div class="card component-card_1">
                    <div class="text-center card-body">
                      <p class="small">
                        <span class="mg-b-0 small"> Elderly Name:</span> <br />
                        <strong>{{ reportToView.elderly_name }}</strong>
                      </p>
                      <p class="small">
                        <span class="mg-b-0 small"> Phone:</span> <br />
                        <strong>{{ reportToView.elderly_phone }}</strong>
                      </p>
                      <!-- <p class=" small"><span class="mg-b-0 small"> Visits Left:</span> <br>  <strong><span class="btn btn-success">{{reportToView.visit_count}}</span></strong></p> -->
                    </div>
                  </div>
                </div>

                <div class="col-md-12 col-lg-9 col-12">
                  <ul class="list-group">
                    <li class="list-group-item">
                      <p class="mg-b-0 small">Appointment Date:</p>
                      <h6>
                        {{ formatFancyDate(reportToView.appointment_date) }}
                      </h6>
                    </li>
                    <li class="list-group-item">
                      <p class="mg-b-0 small">Next Visit:</p>
                      <h6>{{ formatFancyDate(reportToView.next_visit) }}</h6>
                    </li>
                    <li class="list-group-item">
                      <p class="mg-b-0 small">Report:</p>
                      <h6>{{ reportToView.report }}</h6>
                    </li>

                    <li class="list-group-item">
                      <p class="mg-b-0 small">Elderly Remark:</p>
                      <h6>{{ reportToView.elderly_remark }}</h6>
                    </li>

                    <li class="list-group-item">
                      <p class="mg-b-0 small">Sponsor Remark:</p>
                      <h6>{{ reportToView.sponsor_remark }}</h6>
                    </li>
                    <li class="list-group-item">
                      <p class="mg-b-0 small">Medical Services Remark:</p>
                      <h6>{{ reportToView.med_services_remark }}</h6>
                    </li>
                    <li class="list-group-item">
                      <p class="mg-b-0 small">Management Remark:</p>
                      <h6>{{ reportToView.management_remark }}</h6>
                    </li>
                    <li class="list-group-item">
                      <p class="mg-b-0 small">Additional Remark:</p>
                      <h6>{{ reportToView.additional_remark }}</h6>
                    </li>
                  </ul>
                </div>

                <div class="col-12">
                  <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button block v-b-toggle.accordion-2 variant="info">
                        View Full Visit Report
                      </b-button>
                    </b-card-header>
                    <b-collapse
                      id="accordion-2"
                      accordion="my-accordion"
                      role="tabpanel"
                    >
                      <b-card-body>
                        <div class="row">
                          <div class="col-12 col-lg-6">
                            <ul class="list-group">
                              <li class="list-group-item">
                                <p class="mg-b-0 small">Appointment Date:</p>
                                <h6>
                                  {{
                                    formatFancyDate(
                                      reportToView.appointment_date
                                    )
                                  }}
                                </h6>
                              </li>

                              <li class="list-group-item">
                                <p class="mg-b-0 small">Body Temperature:</p>
                                <h6>{{ reportToView.temperature }}</h6>
                              </li>

                              <li class="list-group-item">
                                <p class="mg-b-0 small">Blood Pressure:</p>
                                <h6>{{ reportToView.blood_pressure }}</h6>
                              </li>

                              <li class="list-group-item">
                                <p class="mg-b-0 small">Pulse:</p>
                                <h6>{{ reportToView.pulse }}</h6>
                              </li>

                              <li class="list-group-item">
                                <p class="mg-b-0 small">Breath Rate:</p>
                                <h6>{{ reportToView.breath_rate }}</h6>
                              </li>

                              <li class="list-group-item">
                                <p class="mg-b-0 small">
                                  Presenting Complaint:
                                </p>
                                <h6>{{ reportToView.presenting_complaint }}</h6>
                              </li>

                              <li class="list-group-item">
                                <p class="mg-b-0 small">
                                  Presenting Complaint History:
                                </p>
                                <h6>
                                  {{
                                    reportToView.presenting_complaint_history
                                  }}
                                </h6>
                              </li>

                              <li class="list-group-item">
                                <p class="mg-b-0 small">CNS:</p>
                                <h6>{{ reportToView.cns }}</h6>
                              </li>

                              <li class="list-group-item">
                                <p class="mg-b-0 small">Musculoskeletal:</p>
                                <h6>{{ reportToView.musculoskeletal }}</h6>
                              </li>

                              <li class="list-group-item">
                                <p class="mg-b-0 small">Respiratory:</p>
                                <h6>{{ reportToView.respiratory }}</h6>
                              </li>

                              <li class="list-group-item">
                                <p class="mg-b-0 small">Cardiovascular:</p>
                                <h6>{{ reportToView.cardiovascular }}</h6>
                              </li>

                              <li class="list-group-item">
                                <p class="mg-b-0 small">Abdomen:</p>
                                <h6>{{ reportToView.abdomen }}</h6>
                              </li>

                              <li class="list-group-item">
                                <p class="mg-b-0 small">Genitourinary:</p>
                                <h6>{{ reportToView.genitourinary }}</h6>
                              </li>

                              <li class="list-group-item">
                                <p class="mg-b-0 small">
                                  Drugs to be Purchased:
                                </p>
                                <h6>
                                  {{ reportToView.drugs_to_be_purchased }}
                                </h6>
                              </li>

                              <li class="list-group-item">
                                <p class="mg-b-0 small">
                                  Assessment/Diagnosis:
                                </p>
                                <h6>{{ reportToView.assessment }}</h6>
                              </li>

                              <li class="list-group-item">
                                <p class="mg-b-0 small">Investigation:</p>
                                <h6>{{ reportToView.investigation }}</h6>
                              </li>

                              <li class="list-group-item">
                                <p class="mg-b-0 small">
                                  Reason for Investigation:
                                </p>
                                <h6>{{ reportToView.why_investigation }}</h6>
                              </li>

                              <li class="list-group-item">
                                <p class="mg-b-0 small">Prescription:</p>
                                <h6>{{ reportToView.prescription }}</h6>
                              </li>

                              <li class="list-group-item">
                                <p class="mg-b-0 small">
                                  Reason for Prescription:
                                </p>
                                <h6>{{ reportToView.why_prescription }}</h6>
                              </li>

                              <li class="list-group-item">
                                <p class="mg-b-0 small">Summary:</p>
                                <h6>
                                  <span v-html="reportToView.summary"></span>
                                </h6>
                              </li>
                            </ul>
                          </div>

                          <div class="col-12 col-lg-6">
                            <ul class="list-group">
                              <li class="list-group-item">
                                <p class="mg-b-0 small">Next Visit:</p>
                                <h6
                                  v-if="
                                    reportToView.next_visit == 'null' ||
                                    reportToView.next_visit == ''
                                  "
                                ></h6>
                                <h6 v-else>
                                  {{ formatFancyDate(reportToView.next_visit) }}
                                </h6>
                              </li>

                              <li class="list-group-item">
                                <p class="mg-b-0 small">
                                  Blood Pressure Narration:
                                </p>
                                <h6>
                                  {{ reportToView.blood_pressure_narration }}
                                </h6>
                              </li>

                              <li class="list-group-item">
                                <p class="mg-b-0 small">
                                  Breath Rate Narration:
                                </p>
                                <h6>
                                  {{ reportToView.breath_rate_narration }}
                                </h6>
                              </li>

                              <li class="list-group-item">
                                <p class="mg-b-0 small">Ex_abdomen:</p>
                                <h6>{{ reportToView.ex_abdomen }}</h6>
                              </li>

                              <li class="list-group-item">
                                <p class="mg-b-0 small">Ex_cardiovascular:</p>
                                <h6>{{ reportToView.ex_cardiovascular }}</h6>
                              </li>

                              <li class="list-group-item">
                                <p class="mg-b-0 small">Ex_cns:</p>
                                <h6>{{ reportToView.ex_cns }}</h6>
                              </li>

                              <li class="list-group-item">
                                <p class="mg-b-0 small">Ex_genitourinary:</p>
                                <h6>{{ reportToView.ex_genitourinary }}</h6>
                              </li>

                              <li class="list-group-item">
                                <p class="mg-b-0 small">Ex_musculoskeletal:</p>
                                <h6>{{ reportToView.ex_musculoskeletal }}</h6>
                              </li>

                              <li class="list-group-item">
                                <p class="mg-b-0 small">Ex_respiratory:</p>
                                <h6>{{ reportToView.ex_respiratory }}</h6>
                              </li>

                              <li class="list-group-item">
                                <p class="mg-b-0 small">
                                  Examination Systems gns:
                                </p>
                                <h6>
                                  {{ reportToView.examination_systems_gns }}
                                </h6>
                              </li>

                              <li class="list-group-item">
                                <p class="mg-b-0 small">General Examination:</p>
                                <h6>{{ reportToView.general_examination }}</h6>
                              </li>

                              <li class="list-group-item">
                                <p class="mg-b-0 small">
                                  Pulse Rate Narration:
                                </p>
                                <h6>{{ reportToView.pulse_rate_narration }}</h6>
                              </li>

                              <li class="list-group-item">
                                <p class="mg-b-0 small">Report:</p>
                                <h6>{{ reportToView.report }}</h6>
                              </li>

                              <li class="list-group-item">
                                <p class="mg-b-0 small">Review Systems gcs:</p>
                                <h6>{{ reportToView.review_systems_gcs }}</h6>
                              </li>

                              <li class="list-group-item">
                                <p class="mg-b-0 small">
                                  Temperature Narration:
                                </p>
                                <h6>
                                  {{ reportToView.temperature_narration }}
                                </h6>
                              </li>

                              <li class="list-group-item">
                                <p class="mg-b-0 small">What Specialist:</p>
                                <h6>{{ reportToView.what_specialist }}</h6>
                              </li>

                              <li class="list-group-item">
                                <p class="mg-b-0 small">Admission Required:</p>
                                <h6>
                                  {{
                                    reportToView.admission_required
                                      ? "Yes"
                                      : "No"
                                  }}
                                </h6>
                              </li>

                              <li class="list-group-item">
                                <p class="mg-b-0 small">Specialist Required:</p>
                                <h6>
                                  {{
                                    reportToView.specialist_required
                                      ? "Yes"
                                      : "No"
                                  }}
                                </h6>
                              </li>

                              <li class="list-group-item">
                                <p class="mg-b-0 small">Agreement:</p>
                                <h6>
                                  {{ reportToView.agreement ? "Yes" : "No" }}
                                </h6>
                              </li>

                              <li class="list-group-item">
                                <p class="mg-b-0 small">
                                  Prescription Given to Elderly:
                                </p>
                                <h6>
                                  {{
                                    reportToView.prescription_given_to_elderly
                                      ? "Yes"
                                      : "No"
                                  }}
                                </h6>
                              </li>

                              <li class="list-group-item">
                                <p class="mg-b-0 small">Additional Remark:</p>
                                <h6>{{ reportToView.additional_remark }}</h6>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                class="mr-auto btn btn-info"
                @click.prevent="PrintReport()"
              >
                Print
              </button>
              <button class="ml-auto btn btn-primary" data-dismiss="modal">
                <i class="flaticon-cancel-12"></i> Close
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="editReportModal"
        role="dialog"
        aria-labelledby="editReportModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <div class="mr-auto">
                <small class="modal-title" id="editReportModalLabel"
                  >Visit Report for Elderly {{ reportToEdit.elderly_name }} on
                  {{ formatFancyDate(reportToEdit.appointment_date) }}</small
                >
              </div>
              <div class="ml-auto">
                <span
                  class="shadow-none btn btn-success btn-sm"
                  v-if="reportToEdit.status == 0"
                  >Completed</span
                >
                <span
                  class="shadow-none btn btn-dark btn-sm"
                  v-if="reportToEdit.status == 1"
                  >Not Checked In</span
                >
                <span
                  class="shadow-none btn btn-warning btn-sm"
                  v-if="reportToEdit.status == 2"
                  >Doctor Rescheduled</span
                >
                <span
                  class="shadow-none btn btn-primary btn-sm"
                  v-if="reportToEdit.status == 3"
                  >Patient Rescheduled</span
                >
                <span
                  class="shadow-none btn btn-success btn-sm"
                  v-if="reportToEdit.status == 4"
                  >Completed(couple)</span
                >
              </div>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-lg-12 col-12 layout-spacing">
                  <div class="statbox widget box box-shadow">
                    <div class="widget-content widget-content-area">
                      <form>
                        <div class="row">
                          <div class="mb-4 form-group col-12">
                            <label class="text-dark" for="report">Report</label>
                            <textarea
                              class="form-control"
                              id="report"
                              v-model="reportToEdit.report"
                              rows="2"
                            ></textarea>
                          </div>

                          <div class="mb-4 form-group col-12 col-lg-6">
                            <label class="text-dark" for="elderly_remark"
                              >Elderly Remark</label
                            >
                            <textarea
                              class="form-control"
                              id="elderly_remark"
                              v-model="reportToEdit.elderly_remark"
                              rows="2"
                            ></textarea>
                          </div>

                          <div class="mb-4 form-group col-12 col-lg-6">
                            <label class="text-dark" for="sponsor_remark"
                              >Sponsor Remark</label
                            >
                            <textarea
                              v-model="reportToEdit.sponsor_remark"
                              class="form-control"
                              id="sponsor_remark"
                              rows="2"
                            ></textarea>
                          </div>

                          <div class="mb-4 form-group col-12 col-lg-6">
                            <label class="text-dark" for="med_services_remark"
                              >Med Services Remark</label
                            >
                            <textarea
                              v-model="reportToEdit.med_services_remark"
                              class="form-control"
                              id="med_services_remark"
                              rows="2"
                            ></textarea>
                          </div>

                          <div class="mb-4 form-group col-12 col-lg-6">
                            <label class="text-dark" for="management_remark"
                              >Management Remark</label
                            >
                            <textarea
                              v-model="reportToEdit.management_remark"
                              class="form-control"
                              id="management_remark"
                              rows="2"
                            ></textarea>
                          </div>

                          <div class="mb-4 text-center form-group col-12">
                            <p>Visit Cycle Completed</p>
                            <label
                              class="mb-2 mr-2 switch s-icons s-outline s-outline-success"
                            >
                              <input
                                type="checkbox"
                                v-model="reportToEdit.visit_cycle"
                                checked=""
                              />
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button class="ml-auto btn btn-dark" data-dismiss="modal">
                <i class="flaticon-cancel-12"></i> Close
              </button>
              <button
                type="submit"
                class="mt-4 mb-4 btn btn-primary"
                @click.prevent="handleEditReport()"
                :disabled="loadingEditUser"
              >
                <b-spinner small v-if="loadingEditUser"></b-spinner>
                <span v-else>Save Changes</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Search Modal -->
      <div
        class="modal fade"
        id="searchModal"
        role="dialog"
        aria-labelledby="searchModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="searchModalLabel">Search Options</h5>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label for="dateSearch">Date Search</label>
                    <select
                      class="form-control"
                      v-model="search"
                      id="dateSearch"
                    >
                      <option value="all">All</option>
                      <option value="thisweek">This Week (From Monday)</option>
                      <option value="pastweek">Past Week (Last 7 days)</option>
                      <option value="lastweek">Last Week</option>
                      <option value="lastmonth">Last Month</option>
                      <option value="thismonth">This Month</option>
                      <option value="thisyear">This Year</option>
                      <option value="custom">Custom</option>
                    </select>
                  </div>

                  <div class="form-group" v-if="search == 'custom'">
                    <label>Select a range</label>
                    <div class="input-group">
                      <flat-pickr
                        v-model="date"
                        :config="config"
                        class="form-control"
                        placeholder="Select date"
                        @on-close="handlePicker()"
                        name="date"
                      >
                      </flat-pickr>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button class="btn" data-dismiss="modal">
                <i class="flaticon-cancel-12"></i> Discard
              </button>
              <button
                type="button"
                class="btn btn-primary"
                @click="handleSearch()"
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Doctor exceptional service modal -->
      <div
        class="modal fade"
        id="doctorReviewModal"
        role="dialog"
        aria-labelledby="doctorReviewModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="doctorReviewModalLabel">
                Doctor Exemplary service
              </h5>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <label for="department">Rate performance</label>
                <select
                  class="form-control"
                  v-model="performancePoint"
                  name="department"
                  id="department"
                >
                  <option selected disabled>-- select performance --</option>
                  <option
                    v-for="(performanceMetric, index) in performances"
                    :key="index"
                    :value="performanceMetric.point"
                  >
                    {{ performanceMetric.name.toUpperCase() }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="feedback">Exemplary Service by doctor</label>
                <textarea
                  class="form-control"
                  v-model="review"
                  id="feedback"
                  rows="6"
                ></textarea>
              </div>
            </div>
            <div class="modal-footer">
              <button class="btn" data-dismiss="modal">
                <i class="flaticon-cancel-12"></i> Close
              </button>
              <button
                type="button"
                class="btn btn-primary"
                @click="saveExemplaryService()"
                :disabled="loadingExemplaryService"
              >
                <b-spinner small v-if="loadingExemplaryService"></b-spinner>
                <span v-else
                  >Save Review <i class="fa fa-paper-plane"></i
                ></span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Feedback Modal -->
      <div
        class="modal fade"
        id="feedbackModal"
        role="dialog"
        aria-labelledby="feedbackModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="feedbackModalLabel">Feedback Form</h5>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <label for="feedback">Feedback</label>
                <textarea
                  class="form-control"
                  v-model="feedback"
                  id="feedback"
                  rows="6"
                ></textarea>
              </div>
              <div class="form-group">
                <label for="department">Department</label>
                <select
                  class="form-control"
                  v-model="department"
                  name="department"
                  id="department"
                >
                  <option selected disabled>
                    --select department to notify--
                  </option>
                  <option
                    v-for="(role, index) in $root.userRoles"
                    :key="index"
                    :value="role"
                  >
                    {{ role.toUpperCase() }}
                  </option>
                </select>
              </div>
            </div>
            <div class="modal-footer">
              <button class="btn" data-dismiss="modal">
                <i class="flaticon-cancel-12"></i> Close
              </button>
              <button
                type="button"
                class="btn btn-primary"
                @click="saveFeedback()"
                :disabled="loadingFeedback"
              >
                <b-spinner small v-if="loadingFeedback"></b-spinner>
                <span v-else
                  >Save Feedback <i class="fa fa-paper-plane"></i
                ></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { http } from "@/utils/http";
import { endpoints, geroendpoints } from "@/utils/endpoints";
import { DatePickerConfig } from "@/models/datepicker.js";
import { gerohttp } from "@/utils/gerocarehttp";
import $ from "jquery";
var moment = require("moment");

export default {
  name: "AllVisitReports",
  components: {},

  data() {
    return {
      loading: true,
      reports: {},
      reportToView: {},
      reportToEdit: {},
      exemplaryReport: {},
      loadingEditUser: false,
      loadingExemplaryService: false,

      search: "thismonth",

      performancePoint: "",
      review: "",

      date: null,
      config: new DatePickerConfig(),
      customStart: "",
      customEnd: "",

      loadingUpcoming: false,
      upcomings: [],
      filteredUpcomings: [],
      filteredTable: null,

      range: "all",

      loadingFeedback: false,
      feedback: "",
      department: "",
      currentFeedback: null,
      performances: [
        { name: "Above average", point: 3 },
        { name: "Average", point: 2 },
        { name: "Below average", point: 1 },
      ],
    };
  },

  mounted() {
    this.fetchVisits();
  },

  methods: {
    initTable() {
      var dt = $("#monthtlyvisitreports").DataTable({
        retrieve: true,
        dom: "Bfrtip",
        stateSave: true,
        colReorder: true,
        buttons: [
          {
            extend: "copyHtml5",
            text: 'Copy <i class="fa fa-clone"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "excelHtml5",
            text: 'Excel <i class="fa fa-file-excel"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "pdfHtml5",
            text: 'PDF <i class="fa fa-file-pdf"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "print",
            text: 'Print <i class="fa fa-print"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          "colvis",
        ],
        select: true,
      });
      dt.columns([4, 6, 10, 11, 12, 13, 14]).visible(false);
    },

    initUpcomingTable() {
      this.filteredTable = $("#upcomingVisits").DataTable({
        retrieve: true,
        dom: "Bfrtip",
        stateSave: true,
        colReorder: true,
        buttons: [
          {
            extend: "copyHtml5",
            text: 'Copy <i class="fa fa-clone"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "excelHtml5",
            text: 'Excel <i class="fa fa-file-excel"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "pdfHtml5",
            text: 'PDF <i class="fa fa-file-pdf"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "print",
            text: 'Print <i class="fa fa-print"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          "colvis",
        ],
        select: true,
      });
      //dt.columns([4, 6, 10, 11, 12, 13, 14]).visible(false);
    },

    fetchVisits() {
      this.loading = true;
      http
        .post(endpoints.GET_ALL_VISIT_REPORTS, {
          search: this.search,
          customStart: this.customStart,
          customEnd: this.customEnd,
        })
        .then((response) => {
          this.loading = false;
          this.reports = response;
          setTimeout(() => {
            this.initTable();
          }, 1000);
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },

    viewReport(report) {
      this.reportToView = report;
      $("#viewReportModal").modal("show");
    },

    editReport(report) {
      this.reportToEdit = report;
      $("#editReportModal").modal("show");
    },

    showDoctorExemplary(report) {
      this.exemplaryReport = report;
      $("#doctorReviewModal").modal("show");
    },

    handleEditReport() {
      this.loadingEditUser = true;
      http
        .put(endpoints.UPDATE_VISIT_REPORT, {
          id: this.reportToEdit.id,
          elderly_name: this.reportToEdit.elderly_name,
          appointment_id: this.reportToEdit.appointment_id,
          report: this.reportToEdit.report,
          elderly_remark: this.reportToEdit.elderly_remark,
          sponsor_remark: this.reportToEdit.sponsor_remark,
          med_services_remark: this.reportToEdit.med_services_remark,
          management_remark: this.reportToEdit.management_remark,
          visit_cycle: this.reportToEdit.visit_cycle,
        })
        .then((response) => {
          this.loadingEditUser = false;
          $("#editReportModal").modal("hide");
          this.$toast.success(response);
          // this.fetchVisits()
        })
        .catch((error) => {
          this.loadingEditUser = false;
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },

    saveExemplaryService() {
      this.loadingExemplaryService = true;

      const data = {
        point: this.performancePoint,
        review: this.review,
      };

      gerohttp 
        .post(
          geroendpoints.SAVE_EXEMPLARY_SERVICE.replace(
            ":report",
            this.exemplaryReport.id
          ),
          data
        )
        .then((response) => {
          this.loadingExemplaryService = false;
          $("#doctorReviewModal").modal("hide");
          
          this.$toast.success(response.message);
        })
        .catch((error) => {
          this.loadingExemplaryService = false;
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },

    PrintReport() {
      var HTML_Width = $("#viewReportModalContent").width();
      var HTML_Height = $("#viewReportModalContent").height();
      var top_left_margin = 15;
      var PDF_Width = HTML_Width + top_left_margin * 2;
      var PDF_Height = PDF_Width * 1.5 + top_left_margin * 2;
      var canvas_image_width = HTML_Width;
      var canvas_image_height = HTML_Height;

      var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;

      html2canvas($("#viewReportModalContent")[0], { // eslint-disable-line
        allowTaint: true,
      }).then(function (canvas) {
        canvas.getContext("2d");

        // console.log(canvas.height + "  " + canvas.width);

        var imgData = canvas.toDataURL("image/jpeg", 1.0);
        var pdf = new jsPDF("p", "pt", [PDF_Width, PDF_Height]); // eslint-disable-line
        pdf.addImage(
          imgData,
          "JPG",
          top_left_margin,
          top_left_margin,
          canvas_image_width,
          canvas_image_height
        );

        for (var i = 1; i <= totalPDFPages; i++) {
          pdf.addPage(PDF_Width, PDF_Height);
          pdf.addImage(
            imgData,
            "JPG",
            top_left_margin,
            -(PDF_Height * i) + top_left_margin * 4,
            canvas_image_width,
            canvas_image_height
          );
        }

        pdf.save(`report.pdf`);
      });
    },

    handlePicker() {
      setTimeout(() => {
        if (this.date.length < 15) {
          this.$toast.error("Enter a valid date range");
        } else {
          var split = this.date.split(" ");
          this.customStart = split[0];
          this.customEnd = split[2];
        }
      }, 500);
    },

    handleSearch() {
      if (
        this.search == "custom" &&
        (this.customStart == "" || this.customEnd == "")
      ) {
        this.$toast.error("Enter a valid date range");
      } else {
        this.fetchVisits();
        $("#searchModal").modal("hide");
      }
    },

    fetchUpcomingVisits() {
      if (this.upcomings.length == 0) {
        this.loadingUpcoming = true;
        gerohttp
          .get(geroendpoints.FETCH_UPCOMING_VISITS)
          .then((response) => {
            this.upcomings = response;

            var data = [];
            this.upcomings.forEach((x) => {
              data.push(x.lastappointment.id);
            });
            this.fetchUpcomingVisitsFeedback(data);
          })
          .catch((error) => {
            this.$toast.error(
              error.response?.data?.message || error.response?.message
            );
          });
      }
    },

    fetchUpcomingVisitsFeedback(x) {
      this.loadingUpcoming = true;
      http
        .post(endpoints.FETCH_UPCOMING_APPOINTMENT_FEEDBACK, {
          ids: x,
        })
        .then((response) => {
          this.upcomings.forEach((x) => {
            response.forEach((y) => {
              x.lastappointment.id == y.last_appointment_id
                ? (x.upcomingfeedback = y)
                : "";
            });
          });
          this.sortByTimeRange();
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },

    sortByTimeRange() {
      if (this.range == "thisweek") {
        this.loadingUpcoming = true;

        setTimeout(() => {
          var start = moment().startOf("week");
          var end = moment().endOf("week");
          var data = [];

          this.upcomings.forEach((x) => {
            if (
              moment(x.next_appointment_date).isBetween(start, end) ||
              moment(x.next_appointment_date).isSame(start) ||
              moment(x.next_appointment_date).isSame(end)
            ) {
              data.push(x);
            }
          });

          this.filteredUpcomings = data;
          this.loadingUpcoming = false;

          setTimeout(() => {
            this.initUpcomingTable();
          }, 1000);
        }, 2000);
      } else if (this.range == "nextweek") {
        this.loadingUpcoming = true;

        setTimeout(() => {
          var start = moment().startOf("week").add(7, "d");
          var end = moment().endOf("week").add(7, "d");
          var data = [];

          this.upcomings.forEach((x) => {
            if (
              moment(x.next_appointment_date).isBetween(start, end) ||
              moment(x.next_appointment_date).isSame(start) ||
              moment(x.next_appointment_date).isSame(end)
            ) {
              data.push(x);
            }
          });

          this.filteredUpcomings = data;
          this.loadingUpcoming = false;

          setTimeout(() => {
            this.initUpcomingTable();
          }, 1000);
        }, 2000);
      } else if (this.range == "thismonth") {
        this.loadingUpcoming = true;

        setTimeout(() => {
          var start = moment().startOf("month");
          var end = moment().endOf("month");
          var data = [];

          this.upcomings.forEach((x) => {
            if (
              moment(x.next_appointment_date).isBetween(start, end) ||
              moment(x.next_appointment_date).isSame(start) ||
              moment(x.next_appointment_date).isSame(end)
            ) {
              data.push(x);
            }
          });

          this.filteredUpcomings = data;
          this.loadingUpcoming = false;

          setTimeout(() => {
            this.initUpcomingTable();
          }, 1000);
        }, 3000);
      } else if (this.range == "nextmonth") {
        this.loadingUpcoming = true;

        setTimeout(() => {
          var start = moment().startOf("month").add(1, "M");
          var end = moment().endOf("month").add(1, "M");
          var data = [];

          this.upcomings.forEach((x) => {
            if (
              moment(x.next_appointment_date).isBetween(start, end) ||
              moment(x.next_appointment_date).isSame(start) ||
              moment(x.next_appointment_date).isSame(end)
            ) {
              data.push(x);
            }
          });

          this.filteredUpcomings = data;
          this.loadingUpcoming = false;

          setTimeout(() => {
            this.initUpcomingTable();
          }, 1000);
        }, 3000);
      } else {
        this.loadingUpcoming = true;

        setTimeout(() => {
          this.filteredUpcomings = this.upcomings;
          this.loadingUpcoming = false;

          setTimeout(() => {
            this.initUpcomingTable();
          }, 1000);
        }, 3000);
      }
    },

    startFeedback(item) {
      this.feedback = "";
      this.department = "";

      this.currentFeedback = item;
      if (this.currentFeedback.upcomingfeedback != null) {
        this.feedback = this.currentFeedback.upcomingfeedback.remark;
        this.department = this.currentFeedback.upcomingfeedback.department;
      }
    },

    saveFeedback() {
      if (this.feedback == "") {
        this.$toast.error("Feedback cannot be empty");
      } else {
        this.loadingFeedback = true;
        http
          .put(endpoints.NEW_UPCOMING_APPOINTMENT_FEEDBACK, {
            last_appointment_id: this.currentFeedback.lastappointment.id,
            remark: this.feedback,
            department: this.department,
          })
          .then((response) => {
            this.loadingFeedback = false;
            this.$toast.success(response);
            $("#feedbackModal").modal("hide");

            var data = [];
            this.upcomings.forEach((x) => {
              data.push(x.lastappointment.id);
            });
            this.fetchUpcomingVisitsFeedback(data);
          })
          .catch((error) => {
            this.loadingFeedback = false;
            this.$toast.error(
              error.response?.data?.message || error.response?.message
            );
          });
      }
    },

    parseDelayDuration(days) {
      if (days == null) {
        return "";
      } else if (days == 1) {
        return "1 day ago";
      } else if (days > 1 && days < 7) {
        return `${days} days ago`;
      } else if (days > 7 && days < 30) {
        return `${Math.floor(days / 7)} weeks ago`;
      } else if (days > 30 && days < 60) {
        return `${Math.floor(days / 30)} month ago`;
      } else if (days > 60) {
        return `${Math.floor(days / 30)} months ago`;
      }
    },
  },
};
</script>

<style scoped src="@/assets/css/components/custom-list-group.css"></style>
<style scoped src="@/assets/css/components/cards/card.css"></style>
<style scoped src="@/assets/css/forms/switches.css"></style>
<style scoped src="@/assets/plugins/flatpickr/flatpickr.css"></style>
<style scoped src="@/assets/plugins/flatpickr/custom-flatpickr.css"></style>
